<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-model="filter.status"
                            :items='status'
                            item-text="name"
                            item-value="id"
                            :label='$t("Estado")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="transactionColor"
                v-model="transactionMsj")
                    | {{ transactionText }}
                    v-btn(flat, dark, @click.native="transactionMsj = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Transacciones')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-data-table(
                            :headers='headers'
                            :items='transactions'
                            :pagination.sync='pagination'
                            :rows-per-page-items="$root.rowsPageItems"
                            :rows-per-page-text="$root.rowsPageText" 
                            item-key='uuid'
                        )   
                            template(slot="no-results")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot="no-data")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='header in props.headers' :key='header.text' :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" @click='changeSort(header.value)' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                                        v-icon(small) arrow_upward
                            template(slot='items' slot-scope='props')
                                tr
                                    td {{ props.item.names }}
                                    td {{ props.item.email }}
                                    td
                                        a(target="_blank" :href="props.item.whatsapp") {{ props.item.phone }}
                                    td {{ props.item.base*props.item.quantity }}
                                    td(v-if="props.item.status == 1") {{$t('Completo')}}
                                    td(v-if="props.item.status == 2") {{$t('Pendiente')}}
                                    td(v-if="props.item.status == 3") {{$t('Expirado')}}
                                    td {{props.item.datemodified}}
                                    td {{props.item.expiration}}
                                    td.text-xs-right
                                        v-tooltip(top v-if="props.item.status==2")
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click="confirm(props.item.uuid, 1)") done
                                            span {{$t('Confirmar')}}
                                        v-tooltip(top v-if="props.item.status==2")
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click="confirm(props.item.uuid, 0)") clear
                                            span {{$t('Cancelar')}}
                            template(slot='pageText' slot-scope='props')
                                | {{$t('Filas')}} {{ props.pageStart }} - {{ props.pageStop }} {{$t('de')}} {{ props.itemsLength }}
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import _ from 'lodash/debounce'
    
    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Transacciones')
            }
        },
        data() {
            return {
                preload: false,
                pagination: {},
                drawerRight: false,
                status: [
                    {
                        name: 'Completo',
                        id: 1
                    },
                    {
                        name: 'Pendiente',
                        id: 2
                    },
                    {
                        name: 'Expirado',
                        id: 3
                    }
                ],
                filter: {},
                filterSchema: {
                    query : '',
                    status: []
                },
                transactions : [],
                loadingBtn: false,
                headers: [
                    {
                        text: 'Nombre(s)',
                        align: 'left',
                        value: 'names'
                    },
                    {
                        text: 'Correo electrónico',
                        align: 'left',
                        value: 'email'
                    },
                    {
                        text: 'WhatsApp',
                        align: 'left',
                        value: 'phone'
                    },
                    {
                        text: '# Redes',
                        align: 'left',
                        value: 'base'
                    },
                    {
                        text: 'Estado',
                        align: 'left',
                        value: 'status'
                    },
                    {
                        text: 'Adquisición',
                        align: 'left',
                        value: 'datemodified'
                    },
                    {
                        text: 'Expiración',
                        align: 'left',
                        value: 'expiración'
                    },
                    {
                        text: 'Opciones',
                        align: 'right',
                        value: 'options',
                        sortable: false
                    }
                ],
                transactionMsj  : false,
                transactionColor: '',
                transactionText : '',
            }
        },
        watch: {
            filter: {
                handler(){
                    this.fetchTransactions()
                },
                deep: true
            }
        },
        created(){

            this.fetchTransactions()
            this.filter = Object.assign({}, this.filterSchema)
        },
        methods: {

            fetchTransactions: _(function(){
                
                this.preload = true

                let filter = this.filter
                let params = {
                    filter: JSON.stringify(filter)
                }

                this.$api(this, (xhr) => {
                    xhr.get('/billing', { params: params }).then((r) => {

                        let data = r.data
                        this.preload = false

                        if(data.response){
                            this.transactions = data.data
                        }
                    }).catch(() => {})
                })
            }, 500),

            confirm(billingid, state){

                if(!this.isadmin){
                    return
                }

                let post = {
                    billingid,
                    state
                }

                this.$api(this, (xhr) => {
                    xhr.post('/billing/confirm', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.transactionMsj = true

                        if(data.response){
                            this.transactionColor = 'success'
                            this.transactionText = data.message
                            this.fetchTransactions()
                        }else{
                            this.transactionColor = 'error'
                            this.transactionText = data.message
                        }
                    }).catch(() => {})
                })
            },

            changeSort (column) {
              
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            }
        }
    }
    
</script>